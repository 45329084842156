import { isString } from '@src/utils/string'
import { client } from '../client'

const LOGIN_URL = '/V1/sales-portal/customer/token'
const LOGOUT_URL = '/V1/sales-portal/customer/revoke-token'
const REFRESH_URL = '/V1/sales-portal/customer/refresh-token'
const USERS_URL = 'V1/sales-portal/users'

const AUTHORIZATION_HEADER = 'Authorization'

const formatBearer = (maybeToken) => (isString(maybeToken) ? `Bearer ${maybeToken}` : undefined)

export const setAuthorizationHeader = (maybeToken) => {
	client.injectHeader(AUTHORIZATION_HEADER, formatBearer(maybeToken))
	return maybeToken
}

export const user = {
	// TODO: annotate with typescript or destructure data `{ username, password }`
	login: (data) => client.post({ url: LOGIN_URL, data }),
	logout: () => client.post({ url: LOGOUT_URL }),
	refresh: () => client.post({ url: REFRESH_URL }),
	me: () => client.get({ url: `${USERS_URL}/me` }),
}
