import { genStoreItems, remoteStateItems } from '@src/overmind/core'

const generated = remoteStateItems()

const remoteStateConfig = {
	namespace: 'usersCache',
	getId: (x) => x.id,
	getClientItemState: () => ({}),
	preStoreTransform: (_, u) => u,
}

export const state = {
	...generated.state,
}

export const actions = {
	storeItems: genStoreItems(remoteStateConfig),
}
