import { derived } from 'overmind'
import { products } from '@src/backend/magento/v1'
import { tryLoad, remoteStateItems } from '@src/overmind/core'
import { remoteErrorReaction } from '@src/overmind/util'
import { toObj } from '@src/utils/array'
import { DEFAULT_DEBOUNCE_TIME, elapse } from '@src/utils/time'
import { formatPrice } from 'maxboapp-components'
import * as api from '@src/backend/magento/v1'

const generated = remoteStateItems()

const mapProductToOption = (product) => ({
	id: product.barcode,
	title: `${product.sku} - ${product.name}`,
	subtitle: product.brand || '',
	selected: false,
	price: '',
	sku: product.sku,
})

const paginatePage = async (context, skipProperty) => {
	const s = context.state.products.productSearch

	if (!Number.isFinite(s.paging[skipProperty])) {
		return
	}
	s.result = []
	s.isSearching = true
	const { products, paging } =
		(await tryLoad(s, async () =>
			api.products.search({ query: s.query, skip: s.paging[skipProperty] }),
		)) || []
	s.result = products
	s.paging = paging
	s.isSearching = false
}

export const state = {
	...generated.state,
	productSearch: {
		query: '',
		lastQuery: '',
		queryId: 0,
		result: [],
		isSearching: false,
		isQueryValid: derived((s) => s.query.length >= 3),
		paging: {
			previousSkip: null,
			nextSkip: null,
			currentPage: 1,
		},
	},
	isFetchingPrices: false,
	prices: {},
	productOptions: derived((s) => {
		return s.productSearch.result
			.filter(Boolean)
			.map(mapProductToOption)
			.map((i) => {
				const priceInfo = s.prices[i.id]
				if (!priceInfo) {
					return s.isFetchingPrices ? i : { ...i, price: '-' }
				}

				const { price, unit } = priceInfo
				return { ...i, price: `${formatPrice(price)} ${unit}` }
			})
	}),
	error: null,
}

export const actions = {
	setQuery: (context, query) => {
		const s = context.state.products.productSearch
		s.query = query
	},
	getPrices: async (context) => {
		const s = context.state.products
		const offer = context.state.offers.selectedOffer
		if (s.productSearch.result?.length < 1 || !offer) {
			s.prices = {}
			return
		}

		const { companyId, projectId } = offer

		if (!companyId) {
			s.prices = {}
			return
		}

		s.isFetchingPrices = true
		await tryLoad(
			context.state.products.remote,
			async () => {
				const eans = s.productSearch.result.map((o) => o.barcode)
				const requestData = { companyId, projectId, eans }
				const result = await products.getPrices(requestData)
				s.prices = toObj((r) => r.ean)(result)
				s.isFetchingPrices = false
			},
			() => {
				s.isFetchingPrices = false
			},
		)
	},
	performProductSearch: async (context, query) => {
		const s = context.state.products.productSearch
		const qid = ++s.queryId
		if (!s.isQueryValid) {
			context.actions.products.resetSearch()
			return
		}
		if (s.lastQuery === query) {
			return
		}
		s.isSearching = true
		await elapse(DEFAULT_DEBOUNCE_TIME)
		if (qid !== s.queryId) {
			return // newer concurrent
		}

		s.lastQuery = query

		let data = { products: [], paging: 0 }

		try {
			data = await tryLoad(context.state.products.remote, async () =>
				api.products.search({ query }),
			)
		} catch (_) {
			// Error display taken care of by reaction
		}

		const { products, paging } = data

		if (qid !== s.queryId && s.lastQuery !== query) {
			return // newer concurrent
		}
		s.result = products
		s.paging = paging
		s.isSearching = false
	},
	resetSearch: (context) => {
		const s = context.state.products.productSearch
		s.isSearching = false
		s.result = []
		s.lastQuery = ''
	},
	previousPage: async (context) => {
		return paginatePage(context, 'previousSkip')
	},
	nextPage: async (context) => {
		return paginatePage(context, 'nextSkip')
	},
}

export const onInitialize = (context, overmind) => {
	overmind.reaction((s) => s.products.productSearch.result, context.actions.products.getPrices, {
		nested: false,
		immediate: false,
	})
	overmind.reaction(
		(s) => s.products.productSearch.query,
		context.actions.products.performProductSearch,
		{
			nested: false,
			immediate: false,
		},
	)
	overmind.reaction((s) => s.products.remote.error, remoteErrorReaction(context))
}
