import * as api from '@src/backend/magento/v1'
import { derived } from 'overmind'
import { tryLoad, remoteStateItems, genStoreItems } from '@src/overmind/core'
import { remoteErrorReaction } from '@src/overmind/util'

const generated = remoteStateItems()

export const state = {
	...generated.state,
	error: null,
	isLoading: false,
	list: derived((s) => s.local.db),
}

const remoteStateConfig = {
	namespace: 'stores',
	getId: (x) => x.id,
	getClientItemState: () => ({}),
}

export const actions = {
	storeItems: genStoreItems(remoteStateConfig),
	getById: async (context, storeId) => {
		const s = context.state.stores
		s.isLoading = true
		try {
			const store = await tryLoad(s.remote, () => api.stores.getById(storeId))
			context.actions.stores.storeItems([store])
		} catch (_) {}
		s.isLoading = false
	},
}

export const onInitialize = (context, overmind) => {
	overmind.reaction((s) => s.stores.remote.error, remoteErrorReaction(context))
}
