/* eslint-disable no-param-reassign */
import { derived, json } from 'overmind'
import hash from 'object-hash'
import objectDiff from 'object-diff'
import _merge from 'deepmerge'
import { toObj } from '@src/utils/array'

const hashes = (state) =>
	Object.values(state.db).reduce((o, x) => (!x?.id ? o : Object.assign(o, { [x.id]: hash(x) })), {})

const merge = (x0, x1) =>
	_merge(x0, x1, {
		// NOTE: by default, deepmerge will concat arrays.
		arrayMerge: (a0, a1) => a1,
	})

const getPatch = (a, b) =>
	Object.entries(objectDiff(a || {}, b || {}))
		// NOTE: objectDiff does a shallow comparison of objects - these will always incorrectly diff, however:
		// we are not interested in deep properties, since we are only representing relational table rows, and
		// relational table rows are always flat
		.filter(([, v]) => typeof v !== 'object')
		.reduce((o, [k, v]) => Object.assign(o, { [k]: v }), {})

export const tryLoad = async (remote, f, onError) => {
	try {
		remote.error = null
		remote.isLoading = true
		const data = await f()
		remote.isLoading = false
		return Promise.resolve(data)
	} catch (error) {
		remote.error = formatError(error)
		remote.isLoading = false
		onError?.(error)
		return Promise.reject(error)
	}
}

// DOCS: https://github.com/axios/axios#handling-errors
export const formatError = (error) => {
	if (error?.response) {
		return { message: error.response?.data }
	} else if (error?.request) {
		// The request was made but no response was received
		// e.g. blocking request in browser
		return { message: 'No response from request' }
	} else {
		return { message: error?.message ?? error }
	}
}

const storeItems = (
	{ namespace, getId, getClientItemState, preStoreTransform },
	context,
	items,
) => {
	const s = context.state[namespace]
	const itemsToStore = preStoreTransform ? items.map((i) => preStoreTransform(context, i)) : items

	const patchedItems = itemsToStore.map((newRemoteItem) => {
		const id = getId(newRemoteItem)

		// NOTE: there may be concurrent changes made by both the server and the user
		const oldRemoteItem = json(s.remote.db[id])
		const newLocalItem = json(s.local.db[id])
		const localPatch = getPatch(oldRemoteItem, newLocalItem)

		return {
			...newRemoteItem,
			...localPatch,
		}
	})

	const newRemoteDb = toObj(getId)(itemsToStore)
	const newLocalDb = toObj(getId)(patchedItems)

	s.remote.db = merge(json(s.remote.db), json(newRemoteDb))
	s.local.db = merge(json(s.local.db), json(newLocalDb))

	s.local.client = merge(
		toObj(getId)(
			patchedItems.map((item, i) => ({
				id: getId(item),
				sortIndex: i,
				...getClientItemState(item),
			})),
		),
		json(s.local.client),
	)
}

export const clearItems = (s, ids) => {
	const idsArray = Array.isArray(ids) ? ids : []
	idsArray.forEach((itemId) => {
		delete s.local.db[itemId]
		delete s.local.client[itemId]
		delete s.remote.db[itemId]
	})
}

export const genStoreItems = (remoteStateConfig) => (context, items) =>
	storeItems(remoteStateConfig, context, items)

const deduplicated = new Map()

export const genDeduplicated = (name, f) => {
	const id =
		name || [Date.now().toString(32), (Math.random() * ((1 << 20) - 1)).toString(32)].join('-')
	return async (context, params) => {
		const reqId = hash({ id, params })

		let req = deduplicated.get(reqId)
		if (req) {
			console.debug('deduplicated', { id, params })
			return req
		}

		console.debug('requesting', { id, params })
		req = f(context, params).finally(() => {
			deduplicated.delete(reqId)
		})
		deduplicated.set(reqId, req)

		return req
	}
}

export const genLoadItems = (remoteStateConfig, loadItems, name) => {
	return genDeduplicated(name, async (context, params) => {
		const s = context.state[remoteStateConfig.namespace]
		return tryLoad(s.remote, async () => {
			const rawRes = await loadItems(params)
			storeItems(remoteStateConfig, context, Array.isArray(rawRes) ? rawRes : [])
		})
	})
}

export const genLoadItem = (remoteStateConfig, loadItem, name) => {
	const { namespace, getId, getClientItemState, preStoreTransform, onRemoteUpdate } =
		remoteStateConfig
	return genDeduplicated(name, async (context, params) => {
		const s = context.state[namespace]
		return tryLoad(s.remote, async () => {
			const res = await loadItem(params)
			if (!res) {
				return undefined
			}
			const newRemoteItem = preStoreTransform ? preStoreTransform(context, res) : res
			const id = getId(newRemoteItem)

			// NOTE: there may be concurrent changes made by both the server and the user
			const oldRemoteItem = json(s.remote.db[id])
			const newLocalItem = json(s.local.db[id])
			const localPatch = getPatch(oldRemoteItem, newLocalItem)

			const patchedItem = {
				...newRemoteItem,
				...localPatch,
			}

			s.remote.db[id] = json(newRemoteItem)
			s.local.db[id] = json(patchedItem)
			s.local.client[id] = merge(getClientItemState(patchedItem), json(s.local.client?.[id] ?? {}))

			if (onRemoteUpdate) {
				onRemoteUpdate(context, newRemoteItem)
			}
			return res
		})
	})
}

export const genSaveItem =
	({ namespace, preStoreTransform, onRemoteUpdate }, saveItem) =>
	async (context, id) => {
		const loadContext = {}
		const s = context.state[namespace]
		return tryLoad(
			s.remote,
			async () => {
				loadContext.previous = json(s.remote.db[id])

				const item = json(s.local.db[id])
				s.remote.db[id] = item // optimistic update
				const res = await saveItem(item)
				const newRemoteItem = preStoreTransform ? preStoreTransform(context, res) : res

				// might have accumulated more changes since the save request was fired!
				const oldRemoteItem = json(s.remote.db[id]) // does NOT contain server's changes
				const newLocalItem = json(s.local.db[id]) // superset of local changes
				const localPatch = getPatch(oldRemoteItem, newLocalItem) // actual set of local changes

				if (!oldRemoteItem || !newLocalItem) {
					// might have been concurrently deleted!!
					return
				}

				const patchedItem = {
					...newRemoteItem,
					...localPatch,
				}

				s.remote.db[id] = json(newRemoteItem)
				s.local.db[id] = json(patchedItem)

				if (onRemoteUpdate) {
					onRemoteUpdate(context, newRemoteItem)
				}
			},
			() => {
				s.remote.db[id] = loadContext.previous // too optimistic
			},
		)
	}

export const genDeleteItem =
	({ namespace, onDeleteCascade }, deleteItem) =>
	async (context, id) => {
		const previous = {}
		const s = context.state[namespace]
		return tryLoad(
			s.remote,
			async () => {
				previous.remoteDb = json(s.remote.db[id])
				previous.localDb = json(s.local.db[id])
				previous.localClient = json(s.local.client[id])
				// optimistic update
				delete s.remote.db[id]
				delete s.local.db[id]
				delete s.local.client[id]
				await deleteItem(id).catch((err) => {
					if (err.response.status !== 404) {
						throw err
					}
				})
				onDeleteCascade?.(context, id, previous)
			},
			() => {
				// too optimistic
				s.remote.db[id] = previous.remoteDb
				s.local.db[id] = previous.localDb
				s.local.client[id] = previous.localClient
			},
		)
	}

export const remoteStateItems = () => ({
	state: {
		remote: {
			error: null,
			isLoading: false,
			// TODO: better name?
			db: {}, // treat as immutable - allows diffing with local.db
			hashes: derived(hashes),
		},
		local: {
			db: {}, // This is our local copy of the db data, modifications should be synchronized with db eventually
			client: {}, // This is where we store additional data which should NOT be synchronized in the db
			hashes: derived(hashes),
		},
		diff: derived((state) => {
			const { added, changed, deleted } = Object.entries(state.remote.hashes).reduce(
				(o, [id, oldHash]) => {
					if (!oldHash) {
						return o
					}
					o.added.delete(id)
					const newHash = state.local.hashes[id]
					if (!newHash) {
						o.deleted.add(id)
						return o
					}
					if (newHash !== oldHash) {
						o.changed.add(id)
					}
					return o
				},
				{
					added: new Set(Object.keys(state.local.db)),
					changed: new Set(),
					deleted: new Set(),
				},
			)

			function setToJSON() {
				return [...this].toString()
			}

			added.toJSON = setToJSON
			changed.toJSON = setToJSON
			deleted.toJSON = setToJSON

			return { added, changed, deleted }
		}),
		hasPendingChanges: derived((state) => state.diff.changed.size !== 0),
		isSynchronizingChanges: false,
	},
	actions: {
		commitChanges: async (context) => {
			const { state, actions } = context
			const namespace = context.execution.namespacePath

			const s = state[namespace]
			s.isSynchronizingChanges = true
			try {
				await Promise.all([...s.diff.changed].map((key) => actions[namespace].saveItem(key)))
			} finally {
				s.isSynchronizingChanges = false
			}
		},
	},
})
