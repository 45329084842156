import { recursiveTransform } from '@src/utils/magento'

import { client } from '../client'

const COMPANY_URL = '/V1/sales-portal/company'

export const company = {
	// id -> SalesOfferInterface[]
	getOffers: (id) => client.get({ url: `${COMPANY_URL}/${id}/offers` }).then(recursiveTransform),
	// id -> ProjectInterface[]
	getProjects: (id) =>
		client.get({ url: `${COMPANY_URL}/${id}/projects` }).then(recursiveTransform),
	// id -> CustomerInterface
	get: (id) => client.get({ url: `${COMPANY_URL}/${id}` }).then(recursiveTransform),
}
