// eslint-disable-next-line import/no-extraneous-dependencies
import { navigate } from '@reach/router'
import { createOvermind } from 'overmind'
import { Provider } from 'overmind-react'
import React from 'react'
import Layout from './src/layouts/layout'
import { initOvermind } from './src/overmind'
import { ActivityTracker } from '@src/overmind/activity/ActivityTracker'

const overmind = initOvermind(createOvermind)

export const onClientEntry = () => {
	// Path redirected to by AWS S3 on 404
	const portalPathPrefix = '#!/portal'

	// Workaround for private path loading in conjunction with AWS S3.
	// See https://github.com/gatsbyjs/gatsby/issues/10070
	if (window?.location?.hash?.startsWith(portalPathPrefix)) {
		navigate(window?.location.hash.substring(3), { replace: true })
	}
}

export const wrapPageElement = ({ element, props }) => {
	return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => (
	<Provider value={overmind}>
		<ActivityTracker>{element}</ActivityTracker>
	</Provider>
)
