import { derived, json } from 'overmind'
import { debounce } from '@src/utils/time'

const STATUS_ID = {
	UP_TO_DATE: 0,
	MODIFIED: 1,
	SYNCHRONIZING: 2,
	ERROR: 3,
}

// NOTE: expected to be used for translations
const STATUS_STRINGS = ['up-to-date', 'modified', 'synchronizing', 'error']

export const state = {
	waitTime: 3, // NOTE: Time is measured in seconds
	commitChanges: true,
	status: derived((leafState, rootState) => {
		const status = Object.values(rootState)
			.map((namespace) => {
				if (namespace.error || namespace.remote?.error) {
					return STATUS_ID.ERROR
				}
				if (namespace.isSynchronizingChanges) {
					return STATUS_ID.SYNCHRONIZING
				}
				if (namespace.hasPendingChanges) {
					return STATUS_ID.MODIFIED
				}
				return STATUS_ID.UP_TO_DATE
			})
			.reduce((a, b) => Math.max(a, b), STATUS_ID.UP_TO_DATE)

		// Utility logging:
		if (status === STATUS_ID.MODIFIED) {
			console.log('Detected changes:', leafState.detailedChanges)
		}

		return STATUS_STRINGS[status]
	}),
	numChanges: derived((leafState, rootState) => {
		return Object.values(rootState)
			.map((namespace) => namespace.diff?.changed.size ?? 0)
			.reduce((a, b) => a + b, 0)
	}),
	namespacesWithError: derived((leafState, rootState) => {
		return Object.entries(rootState)
			.map(([namespaceKey, namespace]) =>
				namespace.error || namespace.remote?.error ? namespaceKey : null,
			)
			.filter(Boolean)
	}),
	detailedChanges: derived((leafState, rootState) => {
		const changed = Object.entries(rootState)
			.map(([namespaceKey, namespace]) => {
				const size = namespace.diff?.changed.size
				if (!size) {
					return null
				}

				const ids = [...namespace.diff?.changed]
				return { [namespaceKey]: ids }
			})
			.filter(Boolean)
			.reduce((o, x) => Object.assign(o, x), {})

		return Object.entries(changed)
			.map(([k, v]) => `${k}: ${v.join(', ')}`)
			.join('; ')
	}),
}

export const actions = {
	resetChanges: (context, namespaceKeys) => {
		for (const namespaceKey of namespaceKeys) {
			const { diff, local, remote } = context.state[namespaceKey]
			for (const id of diff.changed) {
				local.db[id] = remote.db[id]
			}
			remote.error = null
		}
	},
	commitChanges: (context) => {
		Object.entries(context.state).forEach(([namespace, namespaceState]) => {
			if (!namespaceState?.diff) {
				return
			}
			// TODO: additionally handle "added":
			// this requires an abstraction for handling creation and id rewrites
			// - new items require a temporary yet unique id
			// - after successful POST the temporary id must change to the response's id
			const { changed, deleted } = namespaceState.diff
			if (changed.size || deleted.size) {
				context.actions[namespace].commitChanges?.()
			}
		})
	},
}

export const onInitialize = async (context, overmind) => {
	const debounceOnChange = debounce((currentState) => {
		const snapshot = json(currentState)
		context.actions.stateTree.backup(snapshot)

		if (currentState.diff.commitChanges) {
			context.actions.diff.commitChanges()
		}
	})

	overmind.reaction(
		(s) => s,
		(s) => {
			const onChange = debounceOnChange(s.diff.waitTime)
			onChange(s)
		},
		{
			nested: true,
			immediate: false,
		},
	)
}
