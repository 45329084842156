import * as api from '@src/backend/magento/v1'
import {
	genLoadItems,
	genSaveItem,
	genDeleteItem,
	genStoreItems,
	remoteStateItems,
	clearItems,
} from '@src/overmind/core'
import { remoteErrorReaction } from '@src/overmind/util'
import { groupBy } from '@src/utils/array'
import { derived, json } from 'overmind'

const generated = remoteStateItems()

const remoteStateConfig = {
	namespace: 'offerGroups',
	getId: (x) => x.id,
	getClientItemState: () => ({}),
	onDeleteCascade: ({ state }, groupId) => {
		const s = state.offerItems
		clearItems(s, s.byGroup[groupId])
	},
}

const groupByOfferId = groupBy(([, v]) => v.offerId)(([k]) => (k == null ? null : Number(k)))
export const state = {
	...generated.state,
	byOffer: derived((s) => groupByOfferId(Object.entries(s.local.db))),
}

export const actions = {
	...generated.actions,
	loadItems: genLoadItems(remoteStateConfig, api.offerGroups.list, 'api.offerGroups.list'),
	saveItem: genSaveItem(remoteStateConfig, api.offerGroups.update),
	storeItems: genStoreItems(remoteStateConfig),
	deleteItem: genDeleteItem(remoteStateConfig, api.offerGroups.deleteById),
	setName: (context, { id, name }) => {
		const x = context.state.offerGroups.local.db[id]
		if (!x) {
			return
		}
		x.name = name || null
	},
	setItemOrder: (context, { id, itemOrder }) => {
		const x = context.state.offerGroups.local.db[id]
		if (!x) {
			return
		}
		x.itemOrder = itemOrder
	},
	create: async (context, { name, offerId }) => {
		const s = context.state.offerGroups
		const created = await api.offerGroups.create({
			offerId,
			name,
		})
		if (!created) {
			return null // TODO: handle unauthorized better?
		}
		const { id } = created
		s.remote.db[id] = json(created)
		s.local.db[id] = created
		s.local.client[id] = remoteStateConfig.getClientItemState()
		return created
	},
}

export const onInitialize = (context, overmind) => {
	overmind.reaction((s) => s.offerGroups.remote.error, remoteErrorReaction(context))
}
