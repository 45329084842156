import { Client2 } from 'maxboapp-components/api/abstract-rest-client'

export const client = Client2({
	baseURL: `${process.env.GATSBY_MAGENTO_PROFF_BASE_URL}/rest`,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
	},
	validateStatus: (status) => [200, 401].includes(status),
	transformResponse: [JSON.parse],
})
