import * as api from '@src/backend/magento/v1'
import { tryLoad, remoteStateItems } from '@src/overmind/core'
import { remoteErrorReaction } from '@src/overmind/util'
import { attemptDebounce, DEFAULT_DEBOUNCE_TIME } from '@src/utils/time'
import { derived } from 'overmind'

const generated = remoteStateItems()

export const state = {
	...generated.state,
	selectedUserId: null,
	selectedUser: derived((s, rs) => {
		return rs.usersCache?.local.db[s.selectedUserId] ?? null
	}),
	search: {
		query: '',
		isSearching: false,
		error: null,
		resultIds: [],
		options: derived((s, rs) => (actions) =>
			s.resultIds.map((userId) => {
				const user = rs.usersCache.local.db[userId]
				return {
					id: userId,
					title: `${user.firstname} ${user.lastname}`,
					subtitle: user.email,
					onClick: () => {
						actions.usersSelection.setSelectedUserId(userId)
					},
					selected: rs.usersSelection.selectedUserId === userId,
				}
			}),
		),
	},
}

export const actions = {
	setSelectedUserId: (context, id) => {
		context.state.usersSelection.selectedUserId = id
	},
	search: {
		setQuery: (context, query) => {
			context.state.usersSelection.search.query = query
		},
		perform: async (context) => {
			const s = context.state.usersSelection.search

			s.isSearching = s.query !== ''
			s.resultIds = []
			const completed = await attemptDebounce(s, DEFAULT_DEBOUNCE_TIME)
			if (!s.query) {
				return
			}

			if (completed) {
				const result =
					(await tryLoad(context.state.usersSelection.remote, async () =>
						api.search.users(s.query),
					)) || []
				s.resultIds = result.map((u) => u.id)
				s.isSearching = false
				context.actions.usersCache.storeItems(result)
			}
		},
	},
}

export const onInitialize = (context, overmind) => {
	overmind.reaction(
		(s) => s.usersSelection.search.query,
		context.actions.usersSelection.search.perform,
		{
			nested: false,
			immediate: false,
		},
	)
	overmind.reaction((s) => s.usersSelection.remote.error, remoteErrorReaction(context))
}
