"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getStorage = exports.setJs = exports.getJs = exports.clear = exports.removeItem = exports.setItem = exports.getItem = void 0;
var function_1 = require("fp-ts/lib/function");
var O = __importStar(require("fp-ts/lib/Option"));
var json_1 = require("./json"); // TODO: wrap in IO
var getItem = function getItem(storage, key) {
    return O.fromNullable(storage.getItem(key));
};
exports.getItem = getItem;
var setItem = function setItem(storage, key, value) {
    return storage.setItem(key, value);
};
exports.setItem = setItem;
var removeItem = function removeItem(storage, key) {
    return storage.removeItem(key);
};
exports.removeItem = removeItem;
var clear = function clear(storage) {
    return storage.clear();
};
exports.clear = clear;
var getJs = function getJs(storage, key) {
    return function_1.pipe(exports.getItem(storage, key), O.chain(json_1.parseJSON));
};
exports.getJs = getJs;
var setJs = function setJs(storage, key, state) {
    return function_1.pipe(json_1.stringifyJSON(state), O.map(function (json) {
        return exports.setItem(storage, key, json);
    }));
};
exports.setJs = setJs;
var getStorage = function getStorage(key) {
    return O.fromNullable({
        localStorage: localStorage,
        sessionStorage: sessionStorage
    }[key]);
};
exports.getStorage = getStorage;
