import { toObj } from '@src/utils/array'
import { recursiveTransform } from '@src/utils/magento'
import { client } from '../client'

const SEARCH_OFFERS_AND_COMPANIES_URL = '/V1/sales-portal/search/offers-and-companies'
const SEARCH_COMPANIES_URL = '/V1/sales-portal/search/companies'
const SEARCH_USERS_URL = '/V1/sales-portal/search/users'
const USERS_RESULT_LIMIT = 10

export const search = {
	offersAndCompanies: (query) =>
		client
			.get({
				url: SEARCH_OFFERS_AND_COMPANIES_URL,
				params: { query },
			})
			.then(({ sales_offers: offers, customers } = {}) => ({
				offers: toObj((x) => `offer/${x.id}`)(recursiveTransform(offers)),
				customers: toObj((x) => `customer/${x.id}`)(recursiveTransform(customers)),
			})),
	companies: (query) =>
		client
			.get({
				url: SEARCH_COMPANIES_URL,
				params: { query },
			})
			.then((data) => (!data ? null : recursiveTransform(data.customers))),
	users: (query, userGroup) =>
		client
			.get({
				url: SEARCH_USERS_URL,
				params: { query, userGroup, limit: USERS_RESULT_LIMIT },
			})
			.then((data) => (!data ? null : recursiveTransform(data))),
}
