import { user, setAuthorizationHeader } from '@src/backend/magento/v1/user'
import { client } from '@src/backend/magento/client'
import { tryLoad, remoteStateItems } from '@src/overmind/core'
import { derived } from 'overmind'
import { PREV_PATH_KEY } from '@src/constants/path'
import { removeItem } from '@src/utils/storage'
import { ms_per_min } from '@src/utils/time'

const generated = remoteStateItems()

export const state = {
	...generated.state,
	error: null,
	isLoading: false,
	hasLoggedIn: false,
	token: null,
	isLoggedIn: derived((s) => Boolean(s.token)),
	userId: null,
	user: derived((s, rs) => rs.usersCache?.local.db[s.userId] ?? null),
	username: derived((s) =>
		s.user ? [s.user.firstname, s.user.lastname].filter(Boolean).join(' ') : '',
	),
	tokenRefresh: {
		timesRefreshed: 0,
		settings: {
			minutesUntilDesired: 50,
			minutesUntilExpired: 60,
		},
		expireTimeout: null,
		expired: false,
		desireTimeout: null,
		desired: false,
		requesting: false,
	},
}

export const onInitialize = (context, overmind) => {
	client.setHandler((res) => {
		if (res.status === 401) {
			if (context.state.auth.hasLoggedIn) {
				context.actions.auth.expireToken()
			}
			return undefined
		}
		return res.data
	})

	overmind.reaction(
		(s) => s.auth.token,
		(maybeToken) => {
			setAuthorizationHeader(maybeToken)
			if (!maybeToken) {
				// NOTE: This will run initially, hence hasLoggedIn
				if (context.state.auth.hasLoggedIn) {
					context.actions.stateTree.clearAndReload()
				}
			} else {
				context.actions.auth.onValidTokenAssigned()
				if (context.state.auth.tokenRefresh.timesRefreshed === 0) {
					context.actions.tabs.loadData()
					context.actions.auth.loadUser()
				}
			}
		},
		{
			nested: false,
			immediate: true,
		},
	)

	// token refresh
	overmind.reaction(
		(s) => {
			// - user token is valid for 1h
			// - after 50 minutes, we want to request a new token (auth.tokenRefresh.settings.minutesUntilDesired)
			// - but if user idled for 15 minutes, do *not* request one (reconfigure with activity.settings.minutesUntilIdle)
			// - however, if user comes back before expiration, fire the request
			const { isIdle } = s.activity
			const { desired, requesting } = s.auth.tokenRefresh
			return desired && !isIdle && !requesting
		},
		(doRequest) => {
			if (doRequest) {
				context.actions.auth.refreshToken()
			}
		},
		{
			nested: false,
			immediate: false,
		},
	)
}

export const actions = {
	onValidTokenAssigned: (context) => {
		context.actions.auth.restartTokenRefreshDesireTimeout()
		context.actions.auth.restartTokenExpirationTimeout()
	},
	restartTokenExpirationTimeout: (context) => {
		const { tokenRefresh } = context.state.auth
		clearTimeout(tokenRefresh.expireTimeout)
		tokenRefresh.expireTimeout = setTimeout(
			context.actions.auth.expireToken,
			ms_per_min * tokenRefresh.settings.minutesUntilExpired,
		)
	},
	restartTokenRefreshDesireTimeout: (context) => {
		const { tokenRefresh } = context.state.auth
		clearTimeout(tokenRefresh.desireTimeout)
		tokenRefresh.desireTimeout = setTimeout(
			context.actions.auth.onDesireTimeout,
			ms_per_min * tokenRefresh.settings.minutesUntilDesired,
		)
	},
	onDesireTimeout: (context) => {
		context.state.auth.tokenRefresh.desired = true
	},
	refreshToken: async (context) => {
		const { auth } = context.state
		const { tokenRefresh } = auth
		tokenRefresh.requesting = true
		try {
			tokenRefresh.timesRefreshed++
			auth.token = await user.refresh()
			tokenRefresh.desired = false
		} catch (error) {
			console.error('unable to refresh token', { error })
			context.actions.auth.expireToken()
		}
		tokenRefresh.requesting = false
	},
	// TODO: annotate data with TS or destructure `{ username, password }`
	login: async (context, data) => {
		const s = context.state.auth
		return tryLoad(s.remote, async () => {
			const token = await user.login(data)
			if (token === undefined) {
				s.error = { message: 'User is unauthorized' }
				return false
			}
			setAuthorizationHeader(token)
			s.error = null
			s.token = token
			s.hasLoggedIn = true
			return true
		})
	},
	expireToken: (context) => {
		context.state.auth.token = null
	},
	logout: async (context) => {
		user.logout() // fire and forget
		removeItem(sessionStorage, PREV_PATH_KEY)
		context.actions.auth.expireToken()
	},
	loadUser: async (context) => {
		const s = context.state.auth
		const result = await tryLoad(s.remote, async () => user.me())
		context.state.auth.userId = result.id
		context.actions.usersCache.storeItems([result])
	},
}
