import { recursiveTransform } from '@src/utils/magento'
import * as A from '@src/utils/array'
import { client } from '../client'
import { deserializeOffer } from './offers'

const OPEN_TABS_URL = '/V1/sales-portal/open-tabs'

export const tabs = {
	list: () =>
		client
			.get({ url: OPEN_TABS_URL })
			.then(recursiveTransform)
			.then(A.map(deserializeOffer)),
	save: (offers) =>
		client
			.post({
				url: OPEN_TABS_URL,
				data: {
					openTabs: {
						offers: A.serialize(offers),
					},
				},
			})
			.then(recursiveTransform)
			.then((data) => A.deserialize(data.offers)),
}
