import 'maxboapp-components/styles/index.scss'
import '@src/layouts/layouts.scss'
import React from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

const bem = new BEMHelper('layout')

const Layout = ({ children }) => {
	return <div {...bem('')}>{children}</div>
}

Layout.propTypes = {
	children: PropTypes.node,
}

export default Layout
