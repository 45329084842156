import * as api from '@src/backend/magento/v1'
import { genDeduplicated, tryLoad, remoteStateItems } from '@src/overmind/core'
import { remoteErrorReaction } from '@src/overmind/util'
import { attemptDebounce, DEFAULT_DEBOUNCE_TIME } from '@src/utils/time'
import { derived, json } from 'overmind'
const generated = remoteStateItems()

const OFFER_HISTORY_LENGTH = 5

export const state = {
	...generated.state,
	query: '',
	timeout: null,
	searchResult: {},
	isLoading: false,
	error: null,
	selectedId: null,
	latestOffers: [],
	companyOffers: [],
	loadingCompanyOffers: false,
	selectedCustomer: derived((s) => {
		const customer = s.selectedId ? s?.searchResult?.customers[s.selectedId] : undefined
		return customer ? json(customer) : null
	}),
	isSearching: false,
	isLoadingLatestOffers: false,
	isQueryValid: derived((s) => s.query.length >= 3),
}

export const actions = {
	setSelected: (context, id) => {
		context.state.dashboard.selectedId = id
	},
	setQuery: (context, query) => {
		context.state.dashboard.query = query
	},
	performSearch: async (context) => {
		const s = context.state.dashboard
		// need to use a separate state than isLoading which is set via tryLoad
		// due to the fact that we're debouncing and want to indicate searching
		// from the moment the debounce starts
		s.isSearching = s.isQueryValid
		s.searchResult = {}
		const completed = await attemptDebounce(s, DEFAULT_DEBOUNCE_TIME)
		if (!s.isQueryValid) {
			return
		}
		if (completed) {
			s.searchResult = await api.search.offersAndCompanies(s.query)
			s.isSearching = false
		}
	},
	removeFromLatestOffers: (context, offerId) => {
		const s = context.state.dashboard
		const idIndex = s.latestOffers.indexOf(offerId)
		if (idIndex >= 0) {
			s.latestOffers.splice(idIndex, 1)
		}
	},
	removeFromCompanyOffers: (context, offerId) => {
		const s = context.state.dashboard
		const idIndex = s.companyOffers.indexOf(offerId)
		if (idIndex >= 0) {
			s.companyOffers.splice(idIndex, 1)
		}
	},
	loadLatestOffers: genDeduplicated('api.offers.list', async (context) => {
		const s = context.state.dashboard
		s.isLoadingLatestOffers = true
		await tryLoad(s.remote, async () => {
			const offers = await api.offers.list({
				pageSize: OFFER_HISTORY_LENGTH,
				sortField: 'modified_at',
				sortDirection: 'DESC',
			})
			context.actions.offers.storeItems(offers)
			s.latestOffers = offers.map((offer) => offer.id)
		}).finally(() => {
			s.isLoadingLatestOffers = false
		})
	}),
	loadCompanyOffers: genDeduplicated('api.company.getOffers', async (context, companyId) => {
		const s = context.state.dashboard
		if (!companyId) {
			s.companyOffers = []
			return
		}
		s.loadingCompanyOffers = true
		await tryLoad(s.remote, async () => {
			const offers = await api.company.getOffers(companyId)
			context.actions.offers.storeItems(offers)
			s.companyOffers = offers.map((offer) => offer.id)
		}).finally(() => {
			s.loadingCompanyOffers = false
		})
	}),
}

export const onInitialize = (context, overmind) => {
	overmind.reaction((s) => s.dashboard.query, context.actions.dashboard.performSearch, {
		nested: false,
		immediate: false,
	})
	overmind.reaction((s) => s.dashboard.remote.error, remoteErrorReaction(context))
}
