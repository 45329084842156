export const state = {
	settings: {
		minutesUntilIdle: 15,
	},
	isIdle: false,
}

export const actions = {
	setToIdle: (context) => {
		context.state.activity.isIdle = true
	},
	setToActive: (context) => {
		context.state.activity.isIdle = false
	},
}
