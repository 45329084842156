import { rehydrate } from 'overmind'
import { pipe } from 'fp-ts/lib/function'
import * as O from 'fp-ts/lib/Option'
import { getJs, removeItem, setJs, setItem } from '@src/utils/storage'
import { PATH, PREV_PATH_KEY } from '@src/constants/path'
import { ensureTrailingSlash } from 'maxboapp-components/utils/url'

const KEY = 'erpapp-state'

function backup(context, state) {
	setJs(sessionStorage, KEY, state)
}
const isPathsEqual = (a, b) => ensureTrailingSlash(a) === ensureTrailingSlash(b)

function clearAndReload() {
	// NOTE: clearing the cache and reloading the window will drop the user token - so redirecting to login is proper
	removeItem(sessionStorage, KEY)
	const pathName = window.location.pathname
	if (!isPathsEqual(pathName, PATH.PORTAL.LOGIN)) {
		const isFirstLoad =
			!sessionStorage.getItem(PREV_PATH_KEY) && isPathsEqual(pathName, PATH.PORTAL.DASHBOARD)
		if (!isFirstLoad) {
			setItem(sessionStorage, PREV_PATH_KEY, pathName)
		}
	} else {
		removeItem(sessionStorage, PREV_PATH_KEY)
	}
	window.location.pathname = PATH.PORTAL.LOGIN
}

export const onInitialize = (context) => {
	pipe(
		getJs(sessionStorage, KEY),
		// eslint-disable-next-line array-callback-return
		O.map((s) => {
			if (s.auth.token) {
				rehydrate(context.state, s)
			}
		}),
	)
}

export const actions = {
	backup,
	clearAndReload,
}
