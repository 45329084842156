"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.capitalizeFirstLetter = exports.toCamelCase = exports.toSnakeCase = exports.isString = exports.ellipsis = void 0;
var ellipsis = function ellipsis(maxLen) {
    return function (str) {
        if (!str) {
            return '';
        }
        if (str.length > maxLen) {
            return str.slice(0, maxLen - 3) + "...";
        }
        return str;
    };
};
exports.ellipsis = ellipsis;
var isString = function isString(x) {
    return typeof x === 'string';
};
exports.isString = isString;
var toSnakeCase = function toSnakeCase(x) {
    return x.replace(/([A-Z]*)([A-Z])/g, function (all, many, single) {
        return many.toLowerCase() + "_" + single.toLowerCase();
    });
};
exports.toSnakeCase = toSnakeCase;
var toCamelCase = function toCamelCase(x) {
    return x.replace(/(_[a-z])/g, function (all, single) {
        return single.slice(1).toUpperCase();
    });
};
exports.toCamelCase = toCamelCase;
var capitalizeFirstLetter = function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};
exports.capitalizeFirstLetter = capitalizeFirstLetter;
