import PropTypes from 'prop-types'
import { useIdleTimer } from 'react-idle-timer'
import * as om from '@src/overmind'
import { ms_per_min, ms_per_s } from '@src/utils/time'

export function ActivityTracker({ children }) {
	const { minutesUntilIdle } = om.useState().activity.settings
	const { setToIdle, setToActive } = om.useActions().activity

	useIdleTimer({
		timeout: ms_per_min * minutesUntilIdle,
		onIdle: setToIdle,
		onActive: setToActive,
		debounce: ms_per_s * 5,
	})

	return children
}
ActivityTracker.propTypes = {
	children: PropTypes.node.isRequired,
}
