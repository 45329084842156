import { restClient } from '../client'

const isDevelopment = process.env.NODE_ENV === 'development'

const sendToPimcore = async (message, level = 'Error') => {
	const data = {
		level,
		message,
		component: 'erpapp',
	}

	try {
		if (isDevelopment) {
			console.log('PimCore log:', { endpoint: '/log/', data: data })
		}
		await restClient.post({ url: '/log/', data: data })
	} catch (error) {
		console.error('Could not log error to Pimcore.')
	}
}

export const remoteLogError = async (
	error,
	fallbackValue = 'unknown fallback error',
	level = 'Error',
) => {
	try {
		let payload = fallbackValue
		if (error && error instanceof Error) {
			payload = error.toString()
		} else if (error && typeof error === 'object') {
			payload = JSON.stringify(error)
		} else if (error && typeof error === 'string') {
			payload = error
		}
		sendToPimcore(payload, level)
	} catch (e) {
		sendToPimcore(e.toString())
	}
}
