"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stringifyJSON = exports.parseJSON = void 0;
var Option_1 = require("fp-ts/lib/Option");
var parseJSON = function parseJSON(text, reviver) {
    return Option_1.tryCatch(function () {
        return JSON.parse(text, reviver);
    });
};
exports.parseJSON = parseJSON;
var stringifyJSON = function stringifyJSON(value, replacer, space) {
    return Option_1.tryCatch(function () {
        return JSON.stringify(value, replacer, space);
    });
};
exports.stringifyJSON = stringifyJSON;
