import * as api from '@src/backend/magento/v1'
import {
	genLoadItems,
	genSaveItem,
	genStoreItems,
	genDeleteItem,
	remoteStateItems,
	tryLoad,
} from '@src/overmind/core'
import { remoteErrorReaction } from '@src/overmind/util'
import { groupBy } from '@src/utils/array'
import { derived, json } from 'overmind'

const generated = remoteStateItems()

const remoteStateConfig = {
	namespace: 'offerItems',
	getId: (x) => x.id,
	getClientItemState: () => ({}),
	onDeleteCascade: ({ state, actions }, itemId, previous) => {
		const { groupId } = previous.localDb
		const { itemOrder } = state.offerGroups.local.db[groupId]
		actions.offerGroups.setItemOrder({
			id: groupId,
			itemOrder: itemOrder.filter((x) => x !== itemId),
		})
	},
}

const groupByGroupId = groupBy(([, v]) => v.groupId)(([k]) => (k == null ? null : Number(k)))
const groupByOfferId = groupBy(([, v]) => v.offerId)(([k]) => (k == null ? null : Number(k)))

export const state = {
	...generated.state,
	byGroup: derived((s) => groupByGroupId(Object.entries(s.local.db))),
	byOffer: derived((s) => groupByOfferId(Object.entries(s.local.db))),
	isCreating: false,
}

const validSchema = (data) => ({
	...data,
	discountPercentage: undefined,
	totalDiscount: undefined,
})

export const actions = {
	...generated.actions,
	loadItems: genLoadItems(remoteStateConfig, api.offerItems.list, 'api.offerItems.list'),
	saveItem: genSaveItem(remoteStateConfig, async (item) =>
		api.offerItems.update(validSchema(item)),
	),
	storeItems: genStoreItems(remoteStateConfig),
	deleteItem: genDeleteItem(remoteStateConfig, api.offerItems.deleteById),
	create: async (context, offerItem) => {
		const s = context.state.offerItems

		s.isCreating = true

		let created = null
		try {
			created = await tryLoad(
				s.remote,
				async () =>
					await api.offerItems.create({
						...offerItem,
						qty: 1,
					}),
				() => {
					s.isCreating = false
				},
			)
		} catch (_) {
			// Error display taken care of by reaction
		}

		s.isCreating = false
		if (!created) {
			return null
		}
		const { id } = created
		s.remote.db[id] = json(created)
		s.local.db[id] = created
		s.local.client[id] = remoteStateConfig.getClientItemState()
		return created
	},
	reset: async (context, id) => {
		const s = context.state.offerItems
		try {
			const resetItem = await tryLoad(s.remote, async () => await api.offerItems.reset(id))
			context.actions.offerItems.storeItems([resetItem])
		} catch (_) {
			// Error display taken care of by reaction
		}
	},
	setGroupId: (context, { id, groupId }) => {
		const { db } = context.state.offerItems.local
		db[id].groupId = Number.parseInt(groupId, 10)
	},
	setQuantity: (context, { id, qty }) => {
		const { db } = context.state.offerItems.local
		db[id].qty = qty >= 1 ? qty : 1
	},
	setOfferedPrice: (context, { id, offeredPrice }) => {
		const { db } = context.state.offerItems.local
		db[id].offeredPrice = offeredPrice
	},
	setComment: (context, { id, comment }) => {
		const { db } = context.state.offerItems.local
		db[id].comment = comment
	},
	setCoverage: (context, { id, coverage }) => {
		const { costPrice } = context.state.offerItems.local.db[id]
		context.actions.offerItems.setOfferedPrice({
			id,
			offeredPrice: coverage ? costPrice / (1 - coverage) : costPrice,
		})
	},
	setCostPrice: (context, { id, costPrice }) => {
		const { db } = context.state.offerItems.local
		db[id].costPrice = costPrice
	},
	setSupplierOfferNumber: (context, { id, supplierOfferNumber }) => {
		const { db } = context.state.offerItems.local
		db[id].supplierOfferNumber = supplierOfferNumber
	},
	setTempCostPrice: (context, { id, costPrice }) => {
		const { client } = context.state.offerItems.local
		client[id].tempCostPrice = costPrice
	},
	setTempSupplierOfferNumber: (context, { id, supplierOfferNumber }) => {
		const { client } = context.state.offerItems.local
		client[id].tempSupplierOfferNumber = supplierOfferNumber
	},
	commitNewCostPrice: (context, { id }) => {
		const { client, db } = context.state.offerItems.local
		if (client[id].tempSupplierOfferNumber) {
			db[id].supplierOfferNumber = client[id].tempSupplierOfferNumber
		}

		if (client[id].tempCostPrice) {
			db[id].costPrice = client[id].tempCostPrice
		}
		client[id].tempSupplierOfferNumber = undefined
		client[id].tempCostPrice = undefined
	},
}

export const onInitialize = (context, overmind) => {
	overmind.reaction((s) => s.offerItems.remote.error, remoteErrorReaction(context))
}
