import { recursiveTransform } from '@src/utils/magento'
import { client } from '../client'

const OFFER_ITEMS_URL = '/V1/sales-portal/offer-items'
const idUrl = (id) => `${OFFER_ITEMS_URL}/${id}`

export const offerItems = {
	list: (offerId) =>
		client.get({ url: OFFER_ITEMS_URL, params: { offerId } }).then(recursiveTransform),
	create: (salesOfferItem) =>
		client.post({ url: OFFER_ITEMS_URL, data: { salesOfferItem } }).then(recursiveTransform),
	getById: (id) => client.get({ url: idUrl(id) }).then(recursiveTransform),
	update: (salesOfferItem) =>
		client
			.put({ url: idUrl(salesOfferItem.id), data: { salesOfferItem } })
			.then(recursiveTransform),
	deleteById: (id) => client.delete({ url: idUrl(id) }),
	reset: (id) => client.put({ url: `${idUrl(id)}/reset` }).then(recursiveTransform),
}
