import * as api from '@src/backend/magento/v1'
import { OFFER } from '@src/constants/offer'
import { tryLoad, remoteStateItems } from '@src/overmind/core'
import { remoteErrorReaction } from '@src/overmind/util'

const generated = remoteStateItems()

export const state = {
	...generated.state,
	error: null,
	isLoading: false,
	openedOffers: [],
}

export const actions = {
	loadData: async (context) => {
		const s = context.state.tabs
		await tryLoad(s.remote, async () => {
			const openedOffers = await api.tabs.list()
			s.openedOffers = openedOffers.map((offer) => offer.id)
			// NOTE: onNavigate in offers-namespace is run before tabs is loaded
			// Need to re-add selected offer to openedOffers if not exists
			if (!s.openedOffers.includes(context.state.offers.selectedId)) {
				s.openedOffers.push(context.state.offers.selectedId)
			}
			context.actions.offers.storeItems(openedOffers)
		})
	},
	saveItem: async (context) => {
		const s = context.state.tabs
		await tryLoad(s.remote, () =>
			api.tabs.save(s.openedOffers.filter((tab) => tab !== OFFER.TAB.NEW)),
		)
	},
	addToOpenedOffers: (context, offerId) => {
		const s = context.state.tabs
		if (offerId) {
			if (!s.openedOffers.includes(offerId)) {
				s.openedOffers = [offerId, ...s.openedOffers]
			}
			if (offerId !== OFFER.TAB.NEW) {
				context.actions.tabs.saveItem()
			}
		}
	},
	removeFromOpenedOffers: (context, offerId) => {
		const s = context.state.tabs
		const idIndex = s.openedOffers.indexOf(offerId)
		if (idIndex >= 0) {
			s.openedOffers.splice(idIndex, 1)
			if (offerId !== OFFER.TAB.NEW) {
				context.actions.tabs.saveItem()
			}
		}
	},
}

export const onInitialize = (context, overmind) => {
	overmind.reaction((s) => s.tabs.remote.error, remoteErrorReaction(context))
}
