import { navigate } from 'gatsby'
import { derived, json } from 'overmind'
import * as api from '@src/backend/magento/v1'

import { OFFER } from '@src/constants/offer'
import { PATH } from '@src/constants/path'
import {
	remoteStateItems,
	genDeduplicated,
	genSaveItem,
	tryLoad,
	genLoadItem,
	genStoreItems,
	genDeleteItem,
	clearItems,
} from '@src/overmind/core'
import { remoteErrorReaction } from '@src/overmind/util'
import { attemptDebounce, DEFAULT_DEBOUNCE_TIME } from '@src/utils/time'
import { toObj } from '@src/utils/array'
import { isPublicOfferId } from '@src/utils/offer'

const generated = remoteStateItems()

const OFFER_404_SYMBOL = Symbol('offer 404')

const remoteStateConfig = {
	namespace: 'offers',
	getId: (x) => x.id,
	getClientItemState: () => ({
		step: {
			active: 3,
			reached: 3,
		},
	}),
	preStoreTransform: ({ actions }, offer) => {
		actions.usersCache.storeItems([offer.owner])
		return {
			...offer,
			owner: undefined,
		}
	},
	onDeleteCascade: ({ state, actions }, offerId) => {
		const caches = [state.offerItems, state.offerGroups, state.attachments]
		caches.forEach((s) => clearItems(s, s.byOffer[offerId]))
		// TODO: dashboard should be decoupled. Event listeners?
		actions.dashboard.removeFromLatestOffers(offerId)
		actions.tabs.removeFromOpenedOffers(offerId)
		actions.dashboard.removeFromCompanyOffers(offerId)
		actions.attachments.deleteByOfferId(offerId)
		const { selectedId } = state.offers
		if (offerId === selectedId) {
			navigate(PATH.PORTAL.OFFER)
		}
	},
	onRemoteUpdate: ({ state, actions }, offer) => {
		if (!state.stores.list[offer.storeId]) {
			actions.stores.getById(offer.storeId)
		}
	},
}

const handlePublicNavigation = async (context, publicOfferId) => {
	const s = context.state.offers
	const { actions } = context
	let data

	try {
		s.loadingSelectedIdData = true
		const [result] = await Promise.all([
			actions.offers.loadItemByPublicId(publicOfferId),
			actions.attachments.loadByPublicId({ publicId: publicOfferId }),
		])
		data = result
		s.loadingSelectedIdData = false
		if (!data.offer) {
			throw new Error('malformed offer')
		}
	} catch (e) {
		navigate(PATH.NOT_FOUND)
		return
	}

	actions.offerGroups.storeItems(data.offerGroups)
	actions.offerItems.storeItems(data.offerItems)
	s.companySearch.result = [...(s.companySearch?.result ?? []), data.company]
	s.projectSearch.result = [...(s.projectSearch?.result ?? []), data.project].filter(Boolean)

	const { offer } = data
	const { id } = offer
	if (s.selectedId !== id) {
		s.selectedId = id
		s.companySearch.query = ''
		s.projectSearch.query = ''
	}
}

const calcTotal = (items) =>
	items.reduce((acc, curr) => acc + (curr.offeredPrice ?? 0) * curr.qty, 0)
const calcEarnings = (items) =>
	items.reduce((acc, curr) => acc + ((curr.offeredPrice ?? 0) - curr.costPrice) * curr.qty, 0)

// The financial term coverage (täckningsgrad)
const calcCoverage = (items) => {
	const totalOfferedPrice = items.reduce(
		(acc, curr) => acc + (curr.offeredPrice ?? 0) * curr.qty,
		0,
	)
	const totalCostPrice = items.reduce((acc, curr) => acc + (curr.costPrice ?? 0) * curr.qty, 0)
	if (totalOfferedPrice === 0) {
		return 0
	}
	return ((totalOfferedPrice - totalCostPrice) / totalOfferedPrice) * 100
}

export const state = {
	...generated.state,
	selectedId: null,
	loadingSelectedIdData: false,
	offerGroupsWithItems: derived(({ selectedOfferDetailed }) =>
		selectedOfferDetailed?.offerGroups.map((group) => ({
			...group,
			items: selectedOfferDetailed.offerItems.filter((item) => item.groupId === group.id),
		})),
	),
	selectedOffer: derived((s) => {
		const db = s.local.db[s.selectedId] ?? {}
		if (!db?.id) {
			return null
		}
		// TODO: move to keys db, client for clarity
		const { companyId, projectId } = db
		const company = companyId ? s.companySearch.idMap[companyId] ?? null : null
		const project = projectId ? s.projectSearch.idMap[projectId] ?? null : null
		const customerErpNo = company?.customAttributes?.erpCustomerNo ?? null
		return {
			...db,
			...s.local.client[s.selectedId],
			companyName: db?.company ?? company?.firstname,
			projectName: db?.project ?? project?.name,
			company,
			project,
			customerErpNo,
		}
	}),
	selectedOfferPrices: derived((s, rs) => {
		const oi = rs.offerItems
		const items = oi.byOffer[s.selectedId]?.map((i) => oi.local.db[i]) ?? []
		return {
			priceTotalExcludingTax: calcTotal(items),
			earningsTotal: calcEarnings(items),
			coverage: calcCoverage(items),
		}
	}),
	companySearch: {
		query: '',
		isSearching: false,
		error: null,
		result: [],
		idMap: derived((s) => toObj((x) => x.id)(s.result)),
		isQueryValid: derived((s) => s.query.length >= 3),
	},
	projectSearch: {
		query: '',
		isSearching: false,
		error: null,
		result: [],
		idMap: derived((s) => toObj((x) => x.id)(s.result)),
	},
}

const validSchema = (data) => ({
	...data,
	createdAt: undefined,
	modifiedAt: undefined,
	publicId: undefined,
	projectName: undefined,
	storeId: undefined,
	owner: undefined,
})

export const actions = {
	...generated.actions,
	loadItemByPublicId: genLoadItem(
		{
			...remoteStateConfig,
			// NOTE: this loads additional properties than `offer` - these are
			// returned by the generated function for subsequent handling on success
			preStoreTransform: (context, data) =>
				remoteStateConfig.preStoreTransform(context, data.offer),
		},
		api.offers.getByPublicId,
		'api.offers.getByPublicId',
	),
	storeItems: genStoreItems(remoteStateConfig),
	loadItem: genLoadItem(remoteStateConfig, api.offers.getById, 'api.offers.getById'),
	saveItem: genSaveItem(remoteStateConfig, async (item) => api.offers.update(validSchema(item))),
	deleteItem: genDeleteItem(remoteStateConfig, api.offers.deleteById),
	createEmpty: (context, { companyId = '' } = {}) => {
		const s = context.state.offers
		const { user, username } = context.state.auth
		const id = OFFER.TAB.NEW
		s.local.db[id] = {
			id,
			title: '',
			statusCode: OFFER.STATUS_CODE.DRAFT,
			companyId,
			projectId: '',
			customerRefName: '',
			customerRefPhone: '',
			salesRefName: username,
			salesRefPhone: user.telephone,
			ownerId: user.id,
		}
		s.local.client[id] = {
			step: {
				active: 1,
				reached: 1,
			},
		}
		context.actions.offers.navigateTo(id)
	},
	setStep: (context, { id, step, isInvalid }) => {
		const os = context.state.offers.local.client[id]
		os.step = {
			active: step,
			reached: Math.max(step, os?.step?.reached ?? 0),
			invalid: isInvalid === true ? step : undefined,
		}
	},
	submitNew: async (context) => {
		const s = context.state.offers
		const created = await api.offers.create({
			...json(s.local.db[OFFER.TAB.NEW]),
			id: undefined,
		})
		if (!created) {
			return // TODO: handle unauthorized better?
		}
		const { id } = created
		s.remote.db[id] = json(created)
		s.local.db[id] = created
		s.local.client[id] = remoteStateConfig.getClientItemState()
		context.actions.tabs.removeFromOpenedOffers(OFFER.TAB.NEW)
		context.actions.tabs.addToOpenedOffers(id)
		s.selectedId = id
		delete s.local.db[OFFER.TAB.NEW]
		delete s.local.client[OFFER.TAB.NEW]
		await tryLoad(s.remote, async () => context.actions.offerGroups.create({ offerId: id }))
		context.actions.offers.setStep({ id, step: 3 })
		context.actions.offers.navigateTo(id)
	},
	onNavigate: async (context, offerId) => {
		if (isPublicOfferId(offerId)) {
			await handlePublicNavigation(context, offerId)
			return
		}
		const num = Number.parseInt(offerId, 10)
		const isDbId = Number.isFinite(num)
		const id = isDbId ? num : offerId
		const s = context.state.offers
		if (s.selectedId !== id) {
			s.selectedId = id
			s.companySearch.query = ''
			s.projectSearch.query = ''
			context.actions.tabs.addToOpenedOffers(id)
		}

		if (isDbId) {
			s.loadingSelectedIdData = true
			const { token } = context.state.auth
			const [result] = await Promise.all([
				context.actions.offers.loadItem(id).catch((error) => {
					if (error.response?.status === 404) {
						return OFFER_404_SYMBOL
					}
					throw error
				}),
				context.actions.offerGroups.loadItems(id),
				context.actions.offerItems.loadItems(id),
				context.actions.attachments.loadItems({ offerId: id, token }),
			]).catch(() => {
				// relevant error has already been handled and other errors are set in
				// their respective loadItems call
				return []
			})

			s.loadingSelectedIdData = false

			if (result === OFFER_404_SYMBOL) {
				// TODO: Handle better in MS-91
				context.actions.tabs.removeFromOpenedOffers(id)
				navigate(PATH.PORTAL.OFFER)
				return
			}
		}

		const { companyId } = s.local.db[s.selectedId] || {}
		context.actions.offers.projectSearch.refresh(companyId)
		context.actions.offers.companySearch.get(companyId)
	},
	navigateTo: (context, offerId) => {
		const pathname = [PATH.PORTAL.OFFER, offerId].filter(Boolean).join('/')
		if (pathname !== window.location.pathname) {
			navigate(pathname) // results in onNavigate after navigation
		}
	},
	setTitle: (context, title) => {
		const s = context.state.offers
		s.local.db[s.selectedId].title = title
	},
	setDescription: (context, description) => {
		const s = context.state.offers
		s.local.db[s.selectedId].description = description
	},
	setCustomerRefName: (context, customerRefName) => {
		const s = context.state.offers
		s.local.db[s.selectedId].customerRefName = customerRefName
	},
	setCustomerRefPhone: (context, customerRefPhone) => {
		const s = context.state.offers
		s.local.db[s.selectedId].customerRefPhone = customerRefPhone
	},
	setSalesRefName: (context, salesRefName) => {
		const s = context.state.offers
		s.local.db[s.selectedId].salesRefName = salesRefName
	},
	setSalesRefPhone: (context, salesRefPhone) => {
		const s = context.state.offers
		s.local.db[s.selectedId].salesRefPhone = salesRefPhone
	},
	setCompanyId: (context, companyId) => {
		const s = context.state.offers
		const db = s.local.db[s.selectedId]
		db.companyId = companyId
		db.projectId = null
		context.actions.offers.projectSearch.setQuery('')
		context.actions.offers.projectSearch.refresh(companyId)
	},
	setStatusCode: (context, statusCode) => {
		const s = context.state.offers
		s.local.db[s.selectedId].statusCode = statusCode
	},
	setGroupOrder: (context, groupOrder) => {
		const s = context.state.offers
		s.local.db[s.selectedId].groupOrder = groupOrder
	},
	setAttachmentOrder: (context, attachmentOrder) => {
		const s = context.state.offers
		s.local.db[s.selectedId].attachmentOrder = attachmentOrder
	},
	changeOwner: async (context, ownerId) => {
		const s = context.state.offers
		s.local.db[s.selectedId].ownerId = ownerId
		await context.actions.offers.commitChanges()
	},
	publish: async (context) => {
		context.actions.offers.setStatusCode(OFFER.STATUS_CODE.PENDING)
		await context.actions.offers.commitChanges()
	},
	placeOrder: async (context) => {
		const s = context.state.offers
		try {
			const offer = await tryLoad(s.remote, async () => api.offers.placeOrder(s.selectedId))
			context.actions.offers.storeItems([offer])
		} catch (error) {
			// Error display taken care of by reaction
			s.error = null
			throw error
		}
	},
	importProducts: async (context, { file }) => {
		const s = context.state.offers
		const response = await api.offers.importProducts(s.selectedId, file)
		const { group, items } = response
		if (group) {
			context.actions.offerGroups.storeItems([group])
			context.actions.offerItems.storeItems(items)
			context.actions.offers.setGroupOrder([group.id, ...s.selectedOffer.groupOrder])
		}
		return response
	},
	duplicate: async (context, { expireOriginal, title }) => {
		const s = context.state.offers
		try {
			const duplicated = await tryLoad(s.remote, async () =>
				api.offers.duplicate(s.selectedId, { title }),
			)
			const { id } = duplicated
			s.remote.db[id] = json(duplicated)
			s.local.db[id] = duplicated
			s.local.client[id] = remoteStateConfig.getClientItemState()

			if (expireOriginal) {
				context.actions.offers.setStatusCode(OFFER.STATUS_CODE.EXPIRED)
			}
			await context.actions.attachments.duplicateByOfferId({
				offerId: s.selectedId,
				duplicateId: id,
			})
			context.actions.offers.navigateTo(id)
		} catch (_) {}
	},
	setProjectId: (context, projectId) => {
		const s = context.state.offers
		const db = s.local.db[s.selectedId]
		db.projectId = projectId
	},
	setExpiryDate: (context, date) => {
		const s = context.state.offers
		s.local.db[s.selectedId].expiryDate = date
	},
	companySearch: {
		setQuery: (context, query) => {
			context.state.offers.companySearch.query = query
		},
		perform: async (context) => {
			const s = context.state.offers.companySearch
			s.isSearching = s.isQueryValid
			s.result = []
			const completed = await attemptDebounce(s, DEFAULT_DEBOUNCE_TIME)
			if (!s.isQueryValid) {
				return
			}
			if (completed) {
				s.result =
					(await tryLoad(context.state.offers.remote, async () => api.search.companies(s.query))) ||
					[]
				s.isSearching = false
			}
		},
		get: genDeduplicated('api.company.get', async (context, id) => {
			const s = context.state.offers.companySearch
			if (!id) {
				s.result = []
				return
			}
			const company = await tryLoad(context.state.offers.remote, async () => api.company.get(id))
			if (company) {
				s.result = [...s.result.filter((x) => x.id !== company.id), company]
			}
		}),
	},
	projectSearch: {
		setQuery: (context, query) => {
			context.state.offers.projectSearch.query = query
		},
		refresh: genDeduplicated('api.company.getProjects', async (context, id) => {
			const s = context.state.offers.projectSearch
			if (!id) {
				s.result = []
				return
			}
			s.result =
				(await tryLoad(context.state.offers.remote, async () => api.company.getProjects(id))) || []
		}),
	},
}

export const onInitialize = (context, overmind) => {
	overmind.reaction(
		(s) => s.offers.companySearch.query,
		context.actions.offers.companySearch.perform,
		{
			nested: false,
			immediate: false,
		},
	)
	overmind.reaction((s) => s.offers.remote.error, remoteErrorReaction(context))
}
