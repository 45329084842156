import * as api from '@src/backend/pimcore/v1'
import { genDeleteItem, genLoadItems, remoteStateItems, tryLoad } from '@src/overmind/core'
import { remoteErrorReaction } from '@src/overmind/util'
import { groupBy } from '@src/utils/array'
import { derived, json } from 'overmind'

const generated = remoteStateItems()

const remoteStateConfig = {
	namespace: 'attachments',
	getId: (x) => x.id,
	getClientItemState: () => ({}),
}

const groupByOfferId = groupBy(([, v]) => v.offerId)(([k]) => (k == null ? null : Number(k)))
export const state = {
	...generated.state,
	isFetching: false,
	isUploading: false,
	byOffer: derived((s) => groupByOfferId(Object.entries(s.local.db))),
}

export const actions = {
	...generated.actions,
	loadItems: (context, { offerId, token }) =>
		genLoadItems(
			remoteStateConfig,
			async () => {
				context.state.attachments.isFetching = true
				const result = await api.attachments.list({ offerId, token })
				context.state.attachments.isFetching = false
				return result
			},
			'api.attachments.list',
		)(context, { offerId, token }),
	loadByPublicId: (context, { publicId }) =>
		genLoadItems(
			remoteStateConfig,
			async () => {
				context.state.attachments.isFetching = true
				const result = await api.attachments.listByPublicId({ publicId })
				context.state.attachments.isFetching = false
				return result
			},
			'api.attachments.listByPublicId',
		)(context, { publicId }),
	deleteItem: (context, { offerId, attachmentId, token }) =>
		genDeleteItem(remoteStateConfig, async () => {
			await api.attachments.deleteById({ offerId, attachmentId, token })
		})(context, attachmentId),
	deleteByOfferId: async (context, offerId) =>
		tryLoad(context.state.attachments.remote, () =>
			api.attachments.deleteByOffer({ offerId, token: context.state.auth.token }),
		),
	duplicateByOfferId: (context, { offerId, duplicateId }) =>
		tryLoad(context.state.attachments.remote, () =>
			api.attachments.duplicateByOffer({ offerId, token: context.state.auth.token, duplicateId }),
		),
	upload: async (context, { file, offerId }) => {
		const s = context.state.attachments
		s.isUploading = true
		let created
		try {
			created = await tryLoad(s.remote, async () =>
				api.attachments.create({ offerId, file, token: context.state.auth.token }),
			)
		} finally {
			s.isUploading = false
		}

		if (!created) {
			return null
		}

		const { id } = created
		s.remote.db[id] = json(created)
		s.local.db[id] = created
		s.local.client[id] = remoteStateConfig.getClientItemState()
		return created
	},
}

export const onInitialize = (context, overmind) => {
	overmind.reaction((s) => s.attachments.remote.error, remoteErrorReaction(context))
}
