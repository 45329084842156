export const OFFER = {
	STATUS_CODE: {
		DRAFT: 'draft',
		PENDING: 'pending',
		LOST: 'lost',
		WON: 'won',
		EXPIRED: 'expired',
	},
	TAB: {
		SEARCH: '',
		NEW: 'new',
	},
}
