export const PATH = {
	PORTAL: {
		DASHBOARD: '/portal',
		MY_OFFER: '/portal/my-offer',
		OFFER: '/portal/offer', // NOTE: Not to be confused with quotations (or RFQs)
		ORDER: '/portal/orders',
		RETURN: '/portal/returns',
		PROFILE: '/portal/user',
		LOGIN: '/portal/login',
		LOGOUT: '/portal/logout',
	},
	NOT_FOUND: '/404',
}

export const PREV_PATH_KEY = 'previous-path'

export const offerPreviewPath = (id) => `/portal/offer/${id}/preview`
