import { map } from '@src/utils/array'
import { client } from '../client'
import { camelKeys } from '../util'

const PRODUCTS_PRICES_URL = '/V1/sales-portal/products/prices'
const PRODUCTS_SEARCH_URL = '/V1/sales-portal/products/search'

export const products = {
	getPrices: (data) =>
		client.post({ url: PRODUCTS_PRICES_URL, data }).then((r) => map(camelKeys)(r?.prices)),
	search: (data) =>
		client.post({ url: PRODUCTS_SEARCH_URL, data }).then(({ products, paging }) => ({
			products: map(camelKeys)(products),
			paging: camelKeys(paging),
		})),
}
