import { client } from '../client'

const MAGENTO_TOKEN_HEADER = 'X-Ateles-Maxbo-Magento-Token'
const ATTACHMENTS_URL = '/salesportal/offers'

const idUrl = (id) => `${ATTACHMENTS_URL}/${id}/attachments`

export const attachments = {
	list: ({ offerId, token }) =>
		client.get({
			url: idUrl(offerId),
			headers: { [MAGENTO_TOKEN_HEADER]: token },
		}),
	listByPublicId: ({ publicId }) =>
		client.get({
			url: `${idUrl(publicId)}/by-public-id`,
		}),
	create: ({ offerId, file, token }) => {
		const formData = new FormData()
		formData.append('attachment', file, file.name)
		return client.post({
			url: idUrl(offerId),
			data: formData,
			headers: { [MAGENTO_TOKEN_HEADER]: token },
		})
	},
	deleteById: ({ offerId, attachmentId, token }) =>
		client.delete({
			url: `${idUrl(offerId)}/${attachmentId}`,
			headers: { [MAGENTO_TOKEN_HEADER]: token },
		}),
	deleteByOffer: ({ offerId, token }) =>
		client.delete({
			url: `${idUrl(offerId)}`,
			headers: { [MAGENTO_TOKEN_HEADER]: token },
		}),
	duplicateByOffer: ({ offerId, token, duplicateId }) =>
		client.post({
			url: `${idUrl(offerId)}/duplicate`,
			data: { duplicateId },
			headers: { [MAGENTO_TOKEN_HEADER]: token },
		}),
}
