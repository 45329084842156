"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapRecursive = exports.mapEntries = exports.mapKeys = void 0;
var mapKeys = function mapKeys(f) {
    return function (obj) {
        return Object.entries(obj).reduce(function (o, _ref) {
            var _Object$assign;
            var k = _ref[0], v = _ref[1];
            return Object.assign({}, o, (_Object$assign = {}, _Object$assign[f(k, v)] = v, _Object$assign));
        }, {});
    };
};
exports.mapKeys = mapKeys;
var mapEntries = function mapEntries(fk) {
    return function (fv) {
        return function (obj) {
            return Object.entries(obj).reduce(function (o, _ref2) {
                var _Object$assign2;
                var k = _ref2[0], v = _ref2[1];
                return Object.assign({}, o, (_Object$assign2 = {}, _Object$assign2[fk(k, v)] = fv(v, k), _Object$assign2));
            }, {});
        };
    };
};
exports.mapEntries = mapEntries;
var mapRecursive = function mapRecursive(fk) {
    return function (fv) {
        return function g(obj) {
            return obj == null || typeof obj !== 'object' ? obj : Array.isArray(obj) ? obj.map(g) : exports.mapEntries(fk)(function (v, k) {
                return g(fv(v, k));
            })(obj);
        };
    };
};
exports.mapRecursive = mapRecursive;
