export const state = {
	messages: [],
}

export const actions = {
	addMessage: (context, { message, extraData, type = 'error' }) => {
		const s = context.state.toast
		if (message) {
			const existingIndex = s.messages.findIndex(
				(msg) => msg.message === message && msg.type === type,
			)
			if (existingIndex >= 0) {
				context.actions.toast.removeMessage(existingIndex)
			}
			s.messages.push({ message, extraData, type })
		}
	},
	removeMessage: (context, messageIndex) => {
		context.state.toast.messages.splice(messageIndex, 1)
	},
}
