import { Client2 } from 'maxboapp-components/api/abstract-rest-client'

const host = process.env.GATSBY_PIMCORE_ERPAPP_BASE_URL
const version = process.env.GATSBY_PIMCORE_ERPAPP_REST_VERSION

export const client = Client2({
	baseURL: `${host}/erpapp/${version}`,
})

export const restClient = Client2({
	baseURL: `${host}/rest/${version}`,
})
