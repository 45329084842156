import { recursiveTransform } from '@src/utils/magento'
import * as A from '@src/utils/array'
import { client } from '../client'
import { deserializeGroup } from './offerGroups'

const OFFERS_URL = '/V1/sales-portal/offers'
const idUrl = (id) => `${OFFERS_URL}/${id}`
const publidIdUrl = (uuid) => `${OFFERS_URL}/public/${uuid}`

const serializeOffer = (data) =>
	!data
		? data
		: {
				...data,
				groupOrder: A.serialize(data.groupOrder),
				attachmentOrder: A.serialize(data.attachmentOrder),
		  }
export const deserializeOffer = (data) =>
	!data
		? data
		: {
				...data,
				groupOrder: A.deserialize(data.groupOrder),
				attachmentOrder: A.deserialize(data.attachmentOrder),
		  }

export const offers = {
	// () -> SalesOfferInterface[]
	list: (params) =>
		client
			.get({
				url: OFFERS_URL,
				params,
			})
			.then(recursiveTransform)
			.then(A.map(deserializeOffer)),

	// SalesOfferInterface -> SalesOfferInterface
	create: (data) =>
		client
			.post({ url: OFFERS_URL, data: { salesOffer: serializeOffer(data) } })
			.then(recursiveTransform)
			.then(deserializeOffer),

	// id -> SalesOfferInterface
	getById: (id) =>
		client
			.get({ url: idUrl(id) })
			.then(recursiveTransform)
			.then(deserializeOffer),

	// uuid -> SalesOfferInterface
	getByPublicId: (uuid) =>
		client
			.get({ url: publidIdUrl(uuid) })
			.then(recursiveTransform)
			.then((publicOffer) => ({
				...publicOffer,
				offer: deserializeOffer(publicOffer.offer),
				offerGroups: publicOffer.offerGroups.map(deserializeGroup),
			})),

	// id, SalesOfferInterface -> SalesOfferInterface
	update: (data) =>
		client
			.put({ url: idUrl(data.id), data: { salesOffer: serializeOffer(data) } })
			.then(recursiveTransform)
			.then(deserializeOffer),

	// id -> void
	deleteById: (id) => client.delete({ url: idUrl(id) }),

	// id, options {title} -> SalesOfferInterface
	duplicate: (id, options = {}) =>
		client
			.post({ url: `${idUrl(id)}/duplicate`, data: { options } })
			.then(recursiveTransform)
			.then(deserializeOffer),

	placeOrder: (id) =>
		client
			.post({ url: `${idUrl(id)}/placeOrder` })
			.then(recursiveTransform)
			.then(deserializeOffer),
	importProducts: (id, file) => {
		const formData = new FormData()
		formData.append('file', file, file.name)

		return client
			.post({ url: `${idUrl(id)}/importProducts`, data: formData })
			.then(recursiveTransform)
	},
}
