"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.recursiveTransform = void 0;
var string_1 = require("@src/utils/string");
var array_1 = require("@src/utils/array");
var object_1 = require("@src/utils/object");
var customAttrToObj = array_1.toObj2(function (x) {
    return x.attribute_code;
})(function (x) {
    return x.value;
});
var transformers = {
    custom_attributes: customAttrToObj,
    messages: function messages(x) {
        if (x.error) {
            console.error(x.error);
        }
        return x;
    }
};
var identity = function identity(x) {
    return x;
};
var transform = function transform(obj, k) {
    var _transformers$k;
    return ((_transformers$k = transformers[k]) !== null && _transformers$k !== void 0 ? _transformers$k : identity)(obj);
};
exports.recursiveTransform = object_1.mapRecursive(string_1.toCamelCase)(transform);
