import { namespaced } from 'overmind/config'
import { createStateHook, createActionsHook } from 'overmind-react'

import * as activity from './activity'
import * as attachments from './attachments'
import * as auth from './auth'
import * as dashboard from './dashboard'
import * as diff from './diff'
import * as offerGroups from './offerGroups'
import * as offerItems from './offerItems'
import * as offers from './offers'
import * as products from './products'
import * as stateTree from './stateTree'
import * as stores from './stores'
import * as tabs from './tabs'
import * as toast from './toast'
import * as usersCache from './usersCache'
import * as usersSelection from './usersSelection'

const namespaces = {
	activity,
	attachments,
	auth,
	dashboard,
	diff,
	offerGroups,
	offerItems,
	offers,
	products,
	stateTree,
	stores,
	tabs,
	toast,
	usersCache,
	usersSelection,
}

export const initOvermind = (factory) =>
	factory(namespaced(namespaces), {
		devtools: process.env.NODE_ENV === 'development',
		hotReloading: process.env.NODE_ENV === 'development',
	})

export const useState = createStateHook()
export const useActions = createActionsHook()
