import { recursiveTransform } from '@src/utils/magento'
import * as A from '@src/utils/array'
import { client } from '../client'

const OFFER_GROUPS_URL = '/V1/sales-portal/offer-groups'
const idUrl = (id) => `${OFFER_GROUPS_URL}/${id}`

const serializeGroup = (data) =>
	!data
		? data
		: {
				...(data || {}),
				itemOrder: A.serialize(data.itemOrder),
		  }
export const deserializeGroup = (data) =>
	!data
		? data
		: {
				...data,
				itemOrder: A.deserialize(data.itemOrder),
		  }

export const offerGroups = {
	list: (offerId) =>
		client
			.get({ url: OFFER_GROUPS_URL, params: { offerId } })
			.then(recursiveTransform)
			.then(A.map(deserializeGroup)),
	create: (salesOfferGroup) =>
		client
			.post({ url: OFFER_GROUPS_URL, data: { salesOfferGroup: serializeGroup(salesOfferGroup) } })
			.then(recursiveTransform)
			.then(deserializeGroup),
	update: (salesOfferGroup) =>
		client
			.put({
				url: idUrl(salesOfferGroup.id),
				data: {
					salesOfferGroup: serializeGroup(salesOfferGroup),
				},
			})
			.then(recursiveTransform)
			.then(deserializeGroup),
	deleteById: (id) => client.delete({ url: idUrl(id) }),
}
